import { graphql } from 'gatsby';
import * as React from 'react';
import { SEO as CommonSEO } from 'src/components/common';
import { LanguageType } from 'src/types/custom-types';

type Props = Queries.HomeSeoFragment & {
  language?: LanguageType;
};

export const fragment = graphql`
  fragment HomeSeo on ContentfulHome {
    entryList {
      eyecatch {
        width
        height
        publicUrl
      }
    }
  }
`;

export const SEO: React.FC<Props> = ({ language = 'ja', entryList }) => {
  const firstImageFile = entryList?.[0]?.eyecatch;

  return (
    <CommonSEO
      pathname={language === 'ja' ? '/' : `/${language}/`}
      image={firstImageFile?.publicUrl}
      imageWidth={firstImageFile?.width}
      imageHeight={firstImageFile?.height}
    />
  );
};

export default SEO;
