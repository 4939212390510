import { type FC } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export const Cross: FC<Props> = ({ className }) => {
  return (
    <svg className={twMerge(className)} preserveAspectRatio="none">
      <line
        x1="0"
        y1="0"
        x2="100%"
        y2="100%"
        stroke="currentColor"
        strokeWidth="1"
        vector-effect="non-scaling-stroke"
      />
      <line
        x1="100%"
        y1="0"
        x2="0"
        y2="100%"
        stroke="currentColor"
        strokeWidth="1"
        vector-effect="non-scaling-stroke"
      />
    </svg>
  );
};

export default Cross;
