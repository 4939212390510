import { type FC } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { Link } from 'src/components/common/atoms';

type Props = {
  className?: string;
  to: string;
  onClick?: () => void;
  src: string;
  alt: string;
  text: string;
};

export const Language: FC<Props> = ({
  className,
  to,
  onClick,
  src,
  alt,
  text,
}) => {
  return (
    <Link
      className={twMerge(
        'group/modal-language flex flex-col justify-between md-sm:gap-[16vh] md-sm:pt-10 md-sm:text-center',
        className
      )}
      to={to}
      onClick={onClick}
    >
      <img
        className={twJoin('h-[27.6vh] md-sm:h-full')}
        src={src}
        alt={alt}
        width="100"
        height="100"
      />
      <p
        className={twJoin(
          'mr-auto text-2xl/none group-hover/modal-language:underline md-sm:mx-auto md-sm:text-xl',
          to === '/en/' && ['line-through-custom']
        )}
      >
        {text}
      </p>
    </Link>
  );
};

export default Language;
