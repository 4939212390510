import { type FC, type ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { useLanguage } from 'src/contexts/language-context';

type Props = {
  className?: string;
  title?: string;
  headingTag?: 'h1' | 'p';
  textBoxTrim?: boolean;
  children?: ReactNode;
};

export const Page: FC<Props> = ({
  className,
  title,
  headingTag: HeadingTag = 'h1',
  textBoxTrim = false,
  children,
}) => {
  const { language } = useLanguage();
  return (
    <div
      className={twMerge(
        'pb-24 pt-20 md-sm:py-0',
        textBoxTrim && language === 'ja' && ['md-sm:-mt-1.5'],
        textBoxTrim && language === 'en' && ['md-sm:-mt-1'],
        className
      )}
    >
      <HeadingTag className={twJoin('text-2xl md-sm:sr-only')}>
        {title}
      </HeadingTag>
      <div className={twJoin('mt-8 md-sm:mt-0')}>{children}</div>
    </div>
  );
};

export default Page;
