import { type FC } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import logoEn from 'src/images/logo_en.svg';
import logoJa from 'src/images/logo_ja.svg';
import { Language } from './language';

type Props = {
  className?: string;
  closeModal(): void;
};

export const Languages: FC<Props> = ({ className, closeModal }) => {
  return (
    <div
      className={twMerge(
        'template-rows-2 grid h-full w-full gap-[18vh] bg-key p-site md-sm:grid-cols-2 md-sm:items-center md-sm:gap-[20%] md-sm:px-[5.4vw]',
        className
      )}
    >
      <Language
        className={twJoin(
          'pointer-events-none flex-col-reverse md-sm:flex-col'
        )}
        to="/en/"
        onClick={closeModal}
        src={logoEn}
        alt="Art Dynamics"
        text="english"
      />
      <Language
        to="/"
        onClick={closeModal}
        src={logoJa}
        alt="アートダイナミクス"
        text="japanese"
      />
    </div>
  );
};

export default Languages;
