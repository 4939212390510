import { type FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { useLanguage } from 'src/contexts/language-context';
import {
  RichTextRenderer,
  type Props as RichTextProps,
} from './rich-text-renderer';

type Props = {
  className?: string;
  document: RichTextProps['document'];
};

export const RichText: FC<Props> = ({ className, document }) => {
  const { language } = useLanguage();
  return (
    <div className={twMerge('rich-text', className)} lang={language}>
      <RichTextRenderer document={document} />
    </div>
  );
};

export default RichText;
