import { graphql } from 'gatsby';
import { type FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { EntryList } from './entry-list';

type Props = Queries.HomeEntryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeEntry on ContentfulHome {
    ...HomeEntryList
  }
`;

export const Entry: FC<Props> = ({ className, ...props }) => (
  <div
    className={twMerge(
      'flex h-full flex-col justify-center md-sm:justify-normal',
      className
    )}
  >
    <EntryList {...props} />
  </div>
);

export default Entry;
