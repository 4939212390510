import { type FC, type ReactNode } from 'react';

import { LanguageContext } from 'src/contexts';
import type { LanguageType } from 'src/types/custom-types';

type Props = {
  children?: ReactNode;
  language: LanguageType;
};

export const Providers: FC<Props> = ({ children, language }) => {
  return (
    <LanguageContext.Provider value={{ language }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default Providers;
