import { Slice, type PageProps } from 'gatsby';
import { useCallback, useState, type FC } from 'react';
import Modal from 'react-modal';
import { twJoin, twMerge } from 'tailwind-merge';

import { Providers } from 'src/components/common';
import { Languages } from 'src/components/home';
import type { PageContextType } from 'src/types/custom-types';

Modal.setAppElement('#___gatsby');

let isInitialHomeRender = true;

const useModalIsOpen = (pathname: string) => {
  const [modalIsOpen, setIsOpen] = useState(
    isInitialHomeRender && (pathname === '/' || pathname === '/en/')
  );
  const closeModal = useCallback(() => {
    setIsOpen(false);
    if (isInitialHomeRender) isInitialHomeRender = false;
  }, []);
  return { modalIsOpen, closeModal };
};

const Layout: FC<PageProps<any, PageContextType>> = ({
  location: { pathname },
  children,
  pageContext: { language = 'ja' },
}) => {
  const { modalIsOpen, closeModal } = useModalIsOpen(pathname);
  // NOTE:
  // gatsby-plugin-offlineを使用すると、
  // swインストール後、一旦fallbackページを経由する場合があり、
  // その際hydrationエラーが発生するため
  // fallbackページの場合はnullを返す
  // エラー内容
  // https://reactjs.org/docs/error-decoder.html/?invariant=418
  // https://reactjs.org/docs/error-decoder.html/?invariant=423
  //
  // gatsby-plugin-offlineの影響で、なぜかlocalにdownloadした画像が表示されない場合があるので、
  // gatsby-plugin-offlineは使用しないことにした。
  // ので、以下は不必要。
  if (pathname === '/offline-plugin-app-shell-fallback/') {
    return null;
  }
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={{
          overlay: {
            zIndex: 100,
            background: 'var(--color-key)',
          },
          content: {
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            width: '100%',
            height: '100%',
            border: 'none',
            padding: '0',
          },
        }}
        onRequestClose={closeModal}
      >
        <Languages closeModal={closeModal} />
      </Modal>
      <div
        className={twMerge(
          'flex min-h-s-screen p-site md-sm:justify-between md-sm:gap-[62px] md-sm:p-site-md md-sm:pb-[92px]',
          modalIsOpen && 'opacity-0'
        )}
      >
        <Slice
          alias="site-header"
          className={twMerge(
            'sticky top-site z-30 md-sm:top-site-md md-sm:min-w-[241px] md-sm:flex-shrink-0 md-sm:self-start'
          )}
          pathname={pathname}
          language={language}
        />
        <Providers language={language}>
          <main className={twJoin('flex-grow md-sm:max-w-main')}>
            {children}
          </main>
        </Providers>
      </div>
    </>
  );
};
export default Layout;
