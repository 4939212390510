import type {
  NodeRenderer,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { graphql } from 'gatsby';
import {
  renderRichText,
  type ContentfulRichTextGatsbyReference,
  type RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { useCallback, useMemo, type FC } from 'react';

import { Image, Link } from 'src/components/common/atoms';
import { BREAKPOINT, MAX_WIDTH_MAIN } from 'src/lib';

// NOTE:
// referensesが必須になっているが、
// 実際はreferensesが無いこともあるので、
// 型操作を行う。
//
type RichTextDocumenType =
  RenderRichTextData<ContentfulRichTextGatsbyReference>;
type CustomRichTextDocumentType = {
  raw: RichTextDocumenType['raw'] | null;
  readonly references?: Queries.Maybe<
    ReadonlyArray<{} | Queries.Maybe<ContentfulRichTextGatsbyReference>>
  >;
};
export type Props = {
  document: CustomRichTextDocumentType;
};

export const fragment = graphql`
  fragment RichTextReferenceHome on ContentfulHome {
    __typename
    contentful_id
    slug
    node_locale
  }
  fragment RichTextReferenceConcept on ContentfulConcept {
    __typename
    contentful_id
    slug
    node_locale
  }
  fragment RichTextReferenceClients on ContentfulClients {
    __typename
    contentful_id
    slug
    node_locale
  }
  fragment RichTextReferenceNews on ContentfulNews {
    __typename
    contentful_id
    slug
    node_locale
  }
  fragment RichTextReferenceOurWork on ContentfulOurWork {
    __typename
    contentful_id
    slug
    node_locale
  }
  fragment RichTextReferenceCompanyProfile on ContentfulCompanyProfile {
    __typename
    contentful_id
    slug
    node_locale
  }
  fragment RichTextReferenceAsset on ContentfulAsset {
    __typename
    contentful_id
    title
    publicUrl
    gatsbyImageData(layout: FULL_WIDTH, quality: 90)
  }
`;

type InternalLink =
  | Queries.RichTextReferenceHomeFragment
  | Queries.RichTextReferenceConceptFragment
  | Queries.RichTextReferenceClientsFragment
  | Queries.RichTextReferenceNewsFragment
  | Queries.RichTextReferenceCompanyProfileFragment
  | Queries.RichTextReferenceOurWorkFragment;

// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/CHANGELOG.md#400-next0-2020-11-09
const useOptions = () => {
  const externalLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const { data } = node;
    if ('uri' in data) {
      return (
        <a href={data.uri} target="_blank" rel="noopenner noreferrer">
          {children}
        </a>
      );
    }
    return null;
  }, []);

  const getHref = (target: Queries.Maybe<InternalLink>) => {
    if (!target) return null;

    const language = target.node_locale;
    if (target.__typename === 'ContentfulHome') {
      return language === 'ja' ? '/' : `/${language}/`;
    } else if (target.__typename === 'ContentfulConcept') {
      return language === 'ja' ? '/concept/' : `/${language}/concept/`;
    } else if (target.__typename === 'ContentfulClients') {
      return language === 'ja' ? '/clients/' : `/${language}/clients/`;
    } else if (target.__typename === 'ContentfulNews') {
      return language === 'ja'
        ? `/news/${target.slug}/`
        : `/${language}/news/${target.slug}/`;
    } else if (target.__typename === 'ContentfulOurWork') {
      return language === 'ja'
        ? `/our-work/${target.slug}/`
        : `/${language}/our-work/${target.slug}/`;
    } else if (target.__typename === 'ContentfulCompanyProfile') {
      return language === 'ja'
        ? '/company-profile/'
        : `/${language}/company-profile/`;
    }
    return null;
  };

  const internalLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const href = getHref(node.data.target);
    if (!href) {
      return children;
    }
    return <Link to={href}>{children}</Link>;
  }, []);

  const assetLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const target = node.data
      .target as Queries.Maybe<Queries.RichTextReferenceAssetFragment>;
    if (!target) return null;
    const href = target.publicUrl;
    if (!href) {
      return;
    }
    return (
      <a href={href} target="_blank" rel="noopenner noreferrer">
        {children}
      </a>
    );
  }, []);

  const embeddedAssetResolver = useCallback<NodeRenderer>((node, children) => {
    const target = node.data
      .target as Queries.Maybe<Queries.RichTextReferenceAssetFragment>;
    if (!target) return null;
    return (
      <Image
        asset={target}
        sizes={`(min-width: ${BREAKPOINT}px) ${MAX_WIDTH_MAIN}px, calc(100vw - 26px)`}
      />
    );
  }, []);

  return useMemo<Options>(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: externalLinkResolver,
        [INLINES.ENTRY_HYPERLINK]: internalLinkResolver,
        [INLINES.ASSET_HYPERLINK]: assetLinkResolver,
        [BLOCKS.EMBEDDED_ASSET]: embeddedAssetResolver,
      },
      // NOTE:
      // 改行を<br>に変換
      // https://github.com/contentful/rich-text/issues/96
      renderText: (text) =>
        text
          .split('\n')
          .flatMap((text, i) => [
            i > 0 && <br key={`line-break-${i}`} />,
            text,
          ]),
    }),
    [
      externalLinkResolver,
      internalLinkResolver,
      assetLinkResolver,
      embeddedAssetResolver,
    ]
  );
};
export const RichTextRenderer: FC<Props> = ({ document }) => {
  const options = useOptions();
  if (!document?.raw) {
    return null;
  }
  return <>{renderRichText(document as RichTextDocumenType, options)}</>;
};

export default RichTextRenderer;
