import { Link as GatsbyLink, type GatsbyLinkProps } from 'gatsby';
import { type FC, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = Pick<
  GatsbyLinkProps<any>,
  'onClick' | 'to' | 'partiallyActive' | 'onPointerEnter' | 'onPointerLeave'
> & {
  className?: string;
  children?: ReactNode;
};

const useIsExternalLink = (to: Props['to']) => {
  if (typeof to === 'string') {
    return to.startsWith('http');
  }
  return false;
};

export const Link: FC<Props> = ({ className, children, to, ...props }) => {
  const isExternalLink = useIsExternalLink(to);
  const mergedClassName = twMerge(className);
  if (isExternalLink) {
    return (
      <a
        className={mergedClassName}
        href={to}
        target="_blank"
        rel="noopenner noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <GatsbyLink
      className={mergedClassName}
      to={to}
      activeClassName="active"
      {...props}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
