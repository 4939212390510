import { type FC } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { Link } from 'src/components/common/atoms';

type Props = {
  className?: string;
  nextPath?: string | null;
  previousPath?: string | null;
};

export const Pagination: FC<Props> = ({
  className,
  nextPath,
  previousPath,
}) => {
  return (
    <ul className={twMerge('mt-6 flex justify-between text-sm', className)}>
      <li aria-hidden={!previousPath}>
        {previousPath && (
          <Link
            className={twJoin('underline hover:no-underline')}
            to={previousPath}
          >
            prev
          </Link>
        )}
      </li>
      <li aria-hidden={!nextPath}>
        {nextPath && (
          <Link
            className={twJoin('underline hover:no-underline')}
            to={nextPath}
          >
            next
          </Link>
        )}
      </li>
    </ul>
  );
};

export default Pagination;
