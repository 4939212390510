import { graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { nonNullableFilter } from 'src/lib';
import { EntryListItem } from './entry-list-item';

type Props = Queries.HomeEntryListFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeEntryList on ContentfulHome {
    entryList {
      contentful_id
      ...HomeEntryListItem
    }
  }
`;

const useCurrentEntryIndex = (entryList: Props['entryList']) => {
  const INTERVAL_DURATION = 5000;
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  useEffect(() => {
    if (entryList) {
      const timerId = setTimeout(() => {
        const nextIndex =
          currentEntryIndex < entryList.length - 1 ? currentEntryIndex + 1 : 0;
        setCurrentEntryIndex(nextIndex);
      }, INTERVAL_DURATION);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [currentEntryIndex, entryList]);
  return currentEntryIndex;
};

export const EntryList: React.FC<Props> = ({ className, entryList }) => {
  const currentEntryIndex = useCurrentEntryIndex(entryList);
  const nonNullableEntryList = entryList?.filter(nonNullableFilter);
  return (
    <ul
      className={twMerge(
        'relative aspect-[3/2] w-full list-none p-0',
        className
      )}
    >
      {nonNullableEntryList?.map((listItem, index) => (
        <EntryListItem
          className={twJoin(
            'absolute inset-0 z-0 opacity-0',
            index === currentEntryIndex && 'z-10 opacity-100'
          )}
          key={listItem?.contentful_id}
          {...listItem}
          loading={index === 0 ? 'eager' : undefined}
        />
      ))}
    </ul>
  );
};

export default EntryList;
