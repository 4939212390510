import { createContext, useContext } from 'react';

export const PathnameContext = createContext<string | null>(null);

export const usePathname = () => {
  const context = useContext(PathnameContext);
  if (context === null) {
    throw new Error('usePathname must be used inside PathnameContext');
  }
  return context;
};
