import { createContext, useContext } from 'react';
import { LanguageType } from 'src/types/custom-types';

export const LanguageContext = createContext({
  language: 'ja' as LanguageType,
});

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === null) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export default LanguageContext;
