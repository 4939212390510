import { GatsbyImage, getImage, type ImageDataLike } from 'gatsby-plugin-image';
import { useCallback, useMemo, useState, type FC } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { Cross } from 'src/components/common';

export type Asset = {
  title?: string | null;
} & (
  | {
      localFile: ImageDataLike;
    }
  | ImageDataLike
);

type Props = {
  className?: string;
  asset: Asset;
  sizes?: string;
  alt?: string | null;
  loading?: 'eager' | 'lazy';
};

const useImage = (asset: Props['asset']) => {
  return useMemo(() => {
    const file =
      'localFile' in asset ? (asset.localFile as ImageDataLike) : asset;
    return getImage(file);
  }, [asset]);
};

const useLoadState = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);
  return { isLoaded, handleLoad };
};

export const Image: FC<Props> = ({ className, asset, sizes, alt, loading }) => {
  const image = useImage(asset);

  const { isLoaded, handleLoad } = useLoadState();
  if (!image) return null;
  return (
    <div className={twMerge('relative', className)}>
      <GatsbyImage
        className={twMerge(!isLoaded && ['opacity-0'])}
        image={image}
        imgStyle={{ transition: 'none' }}
        sizes={sizes}
        alt={alt ?? asset.title ?? ''}
        loading={loading}
        onLoad={handleLoad}
      />
      {!isLoaded && (
        <Cross
          className={twJoin(
            'absolute inset-0 z-10 h-full w-full border border-black'
          )}
        />
      )}
    </div>
  );
};

export default Image;
