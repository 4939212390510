exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-clients-tsx": () => import("./../../../src/templates/clients.tsx" /* webpackChunkName: "component---src-templates-clients-tsx" */),
  "component---src-templates-company-profile-tsx": () => import("./../../../src/templates/company-profile.tsx" /* webpackChunkName: "component---src-templates-company-profile-tsx" */),
  "component---src-templates-concept-tsx": () => import("./../../../src/templates/concept.tsx" /* webpackChunkName: "component---src-templates-concept-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-single-tsx": () => import("./../../../src/templates/news-single.tsx" /* webpackChunkName: "component---src-templates-news-single-tsx" */),
  "component---src-templates-our-work-archive-tsx": () => import("./../../../src/templates/our-work-archive.tsx" /* webpackChunkName: "component---src-templates-our-work-archive-tsx" */),
  "component---src-templates-our-work-single-tsx": () => import("./../../../src/templates/our-work-single.tsx" /* webpackChunkName: "component---src-templates-our-work-single-tsx" */),
  "slice---src-components-slices-site-header-index-tsx": () => import("./../../../src/components/slices/site-header/index.tsx" /* webpackChunkName: "slice---src-components-slices-site-header-index-tsx" */)
}

