import { graphql } from 'gatsby';
import * as React from 'react';
import { Image, Link } from 'src/components/common/atoms';
import { twJoin, twMerge } from 'tailwind-merge';

import { useLanguage } from 'src/contexts/language-context';
import { BREAKPOINT, MAX_WIDTH_MAIN } from 'src/lib';

type Props = Queries.HomeEntryListItemFragment & {
  className?: string;
  loading?: 'eager';
};

export const fragment = graphql`
  fragment HomeEntryListItem on ContentfulOurWork {
    title
    slug
    eyecatch {
      gatsbyImageData(layout: FULL_WIDTH, quality: 90)
    }
  }
`;

export const EntryListItem: React.FC<Props> = ({
  className,
  loading,
  title,
  slug,
  eyecatch,
}) => {
  const { language } = useLanguage();
  const to =
    language === 'ja' ? `/our-work/${slug}/` : `/${language}/our-work/${slug}`;
  if (!title || !slug || !eyecatch) return null;
  return (
    <li className={twMerge(className)}>
      <Link className={twJoin('block h-full w-full')} to={to}>
        <Image
          className={twJoin(
            'h-full w-full [&>div>div:first-child]:hidden [&>div]:h-full'
          )}
          alt={title}
          asset={eyecatch}
          sizes={`(min-width: ${BREAKPOINT}px) ${MAX_WIDTH_MAIN}px, calc(100vw - 26px)`}
          loading={loading}
        />
      </Link>
    </li>
  );
};

export default EntryListItem;
